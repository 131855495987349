<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <div>线上订单</div>
      </template>
    </HeaderBox>
    <div class="selects">
      <!-- 订单类型 -->
      <a-select placeholder="订单类型" style="width: 120px" v-model="order_type">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 商品订单 </a-select-option>
        <a-select-option value="2"> 电子照订单 </a-select-option>
        <a-select-option value="3"> 补考订单 </a-select-option>
        <a-select-option value="4"> 延期订单 </a-select-option>
        <a-select-option value="5"> 直播订单 </a-select-option>
        <a-select-option value="6"> 会员订单 </a-select-option>
      </a-select>
      <!-- 用户类型 -->
      <a-select placeholder="用户类型" style="width: 120px" v-model="user_type">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 个人 </a-select-option>
        <a-select-option value="2"> 机构 </a-select-option>
      </a-select>
      <!-- 支付方式 -->
      <a-select placeholder="支付方式" style="width: 120px" v-model="payMethod">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 微信 </a-select-option>
        <a-select-option value="2"> 支付宝 </a-select-option>
        <a-select-option value="3"> 对公转账 </a-select-option>
        <a-select-option value="4"> 兑换码 </a-select-option>
        <a-select-option value="6"> 赠品 </a-select-option>
      </a-select>
      <!-- 退款状态 -->
      <!-- <a-select
        placeholder="退款状态"
        style="width: 120px"
        v-model="refund_status"
      >
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 待卖家处理 </a-select-option>
        <a-select-option value="2"> 部分退款 </a-select-option>
        <a-select-option value="3"> 已退款 </a-select-option>
        <a-select-option value="4"> 退款关闭 </a-select-option>
      </a-select> -->
      <!-- 活动来源 -->
      <!-- <a-select placeholder="活动来源" style="width: 120px" v-model="source">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 套餐 </a-select-option>
      </a-select> -->
      <!-- 拆单状态 -->
      <!-- <a-select placeholder="拆单状态" style="width: 120px" v-model="is_split">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="1"> 已拆单 </a-select-option>
        <a-select-option value="0"> 未拆单 </a-select-option>
      </a-select> -->
      <!-- 订单来源 -->
      <a-select placeholder="订单来源" style="width: 120px" v-model="orderSource">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="PC"> PC </a-select-option>
        <a-select-option value="APP"> APP </a-select-option>
        <a-select-option value="订单补录"> 订单补录 </a-select-option>
        <a-select-option value="数据迁移"> 数据迁移 </a-select-option>
        <a-select-option value="二维码支付"> 二维码支付 </a-select-option>
      </a-select>

      <!-- 跟单人 -->
      <a-select placeholder="跟单人" style="width: 120px" v-model="orderFollower" :getPopupContainer="triggerNode => {
        return triggerNode.parentNode || document.body;
      }">
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="卫清静"> 卫清静 </a-select-option>
        <a-select-option value="赵雪"> 赵雪 </a-select-option>
        <a-select-option value="杨柳"> 杨柳 </a-select-option>
        <a-select-option value="汪玲"> 汪玲 </a-select-option>
        <a-select-option value="杨少云"> 杨少云 </a-select-option>
        <a-select-option value="kong"> 无 </a-select-option>
      </a-select>

      <a-form-item label="支付时间：" style="display: inline-flex; margin: 0">
        <a-range-picker valueFormat="YYYY-MM-DD HH:mm:ss" showTime v-model="findtime"></a-range-picker>
      </a-form-item>
    </div>
    <div class="findItem">
      <a-input v-if="false" v-model="productName" placeholder="请输入商品名称" />
      <!-- <a-input v-model="userName" placeholder="请输入手机号/负责人手机号" /> -->
      <a-input v-model="userName" placeholder="请输入手机号/负责人手机号" />
      <a-input v-model="code" placeholder="请输入订单编码" />
      <a-input v-model="institutionname" placeholder="请输入姓名/机构名称" />

      <!-- 个人商品订单 模糊搜索 -->
      <a-select style="width: 300px" show-search v-model="productId" placeholder="请选择商品"
        :default-active-first-option="false" :show-arrow="false" :filter-option="false" :not-found-content="null"
        @search="shophandleSearch" @change="selectProduct">
        <a-select-option v-for="item in productSelectList" :key="item.productId">
          {{ item.productName }}
        </a-select-option>
      </a-select>
    </div>
    <div class="findItem">
      <a-button type="primary" @click="search()" v-hasPermi="['onlineorder:list:query']">搜索</a-button>
      <a-button class="all_btn_border btnBorder" @click="reset">重置</a-button>
      <a-button class="all_btn_border btnBorder" @click="getExportlist"
        v-hasPermi="['onlineorder:list:export']">自定义字段导出</a-button>
      <a :href="$config.target + '/hxclass-management/order/excel/shipment/template'
        ">
        <a-button class="all_btn_border btnBorder">下载模板</a-button>
      </a>
      <a-upload v-hasPermi="['onlineorder:list:inport']" style="width: 110px; margin-left: 24px" :showUploadList="false"
        name="file" :customRequest="fileAction">
        <a-button type="primary">导入物流</a-button>
      </a-upload>
      <a-button class="all_btn_border btnBorder" @click="remarksAll">批量备注</a-button>
      <a-button class="all_btn_border btnBorder" @click="exportTable">商品回款报表导出</a-button>
      <a-button class="all_btn_border btnBorder" v-hasPermi="['onlineorder:financial:export']" :loading="loadExport"
        @click="exportMoneyOrder">财务订单导出</a-button>
      <a-button class="all_btn_border btnBorder" :loading="NewloadExport" @click="exportnewOrder">固定订单明细导出</a-button>
    </div>

    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs :default-active-key="tabIndex" @change="callback">
        <a-tab-pane :key="0" tab="全部订单"></a-tab-pane>
        <a-tab-pane :key="1" tab="待付款"></a-tab-pane>
        <a-tab-pane :key="2" tab="待发货"></a-tab-pane>
        <a-tab-pane :key="3" tab="已发货"></a-tab-pane>
        <a-tab-pane :key="4" tab="交易完成"></a-tab-pane>
        <a-tab-pane :key="5" tab="交易关闭"></a-tab-pane>
        <a-tab-pane :key="7" tab="已支付"></a-tab-pane>
        <!-- <a-tab-pane key="8" tab="退款"></a-tab-pane> -->
      </a-tabs>
      <a-table class="table-template" :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: select,
        onSelectAll: selectAll,
        columnTitle: '选择',
      }" :rowKey="(item) => item.orderId" :loading="loadList" :columns="columns" :data-source="tableData"
        :scroll="{ x: true }" @change="onPage" :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">
        <template slot="footer">
          <a-checkbox @change="onChange" :checked="isChecked" class="allSellect">
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, index">
          <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>
        <template slot="productName" slot-scope="text">
          <p class="productName" :title="text">{{ text ? text : "--" }}</p>
        </template>
        <template slot="payment" slot-scope="text">
          {{ text ? text : "--" }}
        </template>
        <template slot="mobile" slot-scope="text">
          <p class="mobile" :title="text">{{ text ? $encryption('empty', 'phone', text) : "--" }}</p>
        </template>
        <template slot="freight" slot-scope="text">
          {{ text ? text : "--" }}
        </template>

        <template slot="source" slot-scope="text">
          {{ text ? text : "--" }}
        </template>

        <template slot="tradeId" slot-scope="text">
          {{ text ? text : "--" }}
        </template>

        <template slot="changePrice" slot-scope="text">
          {{ text ? (text > 0 ? "+" + text : text) : "--" }}
        </template>

        <template slot="changeProductFlag" slot-scope="text">
          {{ text ? (text == 2 ? '换课新订单' : text == 1 ? '换课老订单' : '非换课订单') : "--" }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <a v-if="record.status == 1" @click="godetail(record.orderId, record.userType)">改价</a>
            <span v-if="record.status == 1">|</span>
            <a v-hasPermi="['onlineorder:pay:updatestatus']" @click="infomationExamine(record)"
              v-if="record.payMethod == 3">{{ (record.transferStatus === 0 && record.orderSource != '订单补录') ? '对公转账审核' :
              '对公转账信息'}}</a>
            <span v-if="record.payMethod == 3">|</span>
            <a v-hasPermi="['logistics:address:edit']" @click="getOrderDetail(record.orderId)"
              v-if="record.status == 2">修改地址</a>
            <span v-if="record.status == 2">|</span>
            <a v-if="
              record.showBtnShipment &&
              (record.status == 2 || record.status == 4)
            " @click="godetail(record.orderId, record.userType)">发货</a>
            <span v-if="
              record.showBtnShipment &&
              (record.status == 2 || record.status == 4)
            ">|</span>
            <a v-if="record.isAuditComment" @click="godetail(record.orderId, record.userType)">评价审核</a>
            <span v-if="record.isAuditComment">|</span>
            <a v-if="record.isInvoice" @click="invoiceMessage(record)">发票信息</a>
            <span v-if="record.isInvoice">|</span>
            <a @click="godetail(record.orderId, record.userType)">详情</a>
            <span>|</span>
            <a
              @click="(followerVisible = true), (orderId = record.orderId), (handleFollowerList(record.orderFollower))">跟单人</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 修改学员收货信息 -->
    <a-modal :closable="false" title="修改学员收货信息" :width="800" :visible="visible" :confirm-loading="confirmLoading"
      @ok="handleOk" @cancel="handleCancel">
      <template>
        <a-table class="table-template" :columns="columns2" :data-source="modelTableData" @change="onPage"
          :scroll="{ y: 240 }" :pagination="false">
          <template slot="name" slot-scope="text, record, index">
            <a-input style="border: 0; text-align: center" v-model="modelTableData[index].name" placeholder="请输入收货人"
              @input="dataConsole" />
          </template>
          <template slot="phone" slot-scope="text, record, index">
            <a-input style="border: 0; text-align: center" v-model="modelTableData[index].phone" placeholder="请输入收货人手机号"
              @input="dataConsole" />
          </template>
          <template slot="address" slot-scope="text, record, index">
            <a-input style="border: 0; text-align: center" v-model="modelTableData[index].address"
              placeholder="请输入收货人地址" @input="dataConsole" />
          </template>
          <template slot="addressDetail" slot-scope="text, record, index">
            <a-input style="border: 0; text-align: center" v-model="modelTableData[index].addressDetail"
              placeholder="请输入收货人详细地址" @input="dataConsole" />
          </template>
        </a-table>
      </template>
    </a-modal>
    <!-- 对公转账信息 -->
    <a-modal title="对公转账信息" :width="infomationObj && infomationObj.img.length >= 2 ? 900 : 700" :visible="publicVisible"
      :confirm-loading="confirmLoading" @cancel="modelCancel">
      <template slot="footer">
        <a-button type="primary" v-show="infomationObj && infomationObj.state == 0" @click="paymented">确认收款</a-button>
      </template>
      <div class="linefeed">
        <p class="message" style="width: 130px;">
          付款人户名：
        </p>
        <p class="message">{{ infomationObj ? infomationObj.accountName : "" }}</p>
        <p class="message" style="width: 130px;margin-left: 30px;">
          开户行：
        </p>
        <p class="message">{{ infomationObj ? infomationObj.bankName : "" }}</p>
      </div>
      <div class="linefeed">
        <p class="message" style="width: 130px;">
          付款人账号：
        </p>
        <p class="message">{{ infomationObj ? infomationObj.accountNumber : "" }}</p>
        <p class="message" style="width: 130px;margin-left: 30px;">
          应付金额：
        </p>
        <p class="message">{{ infomationObj ? infomationObj.price : '' }}元</p>
      </div>


      <div class="img" v-if="infomationObj && infomationObj.img.length">
        <p>支付凭证：</p>
        <div class="imgs">
          <img v-for="(item, index) in infomationObj.img" :key="index" @click="lookImg(item)" title="点击查看图片" :src="item"
            alt="" />
        </div>
      </div>
      <a-row class="rows message" v-show="infomationObj">
        <span>收款方:</span>
        <a-col span="16">
          <a-select :disabled="infomationObj && infomationObj.state != 0" placeholder="请选择收款方" style="width: 100%"
            v-model="billingAgency" @change="billingAgencyChange">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option :key="1">元道</a-select-option>
            <a-select-option :key="2">出版社</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows message" v-show="infomationObj">
        <span>收款时间:</span>
        <a-col span="16">
          <a-date-picker :disabled="infomationObj && infomationObj.state != 0" style="width: 100%" v-model="paymentTime"
            :show-time="{ format: 'HH:mm:ss' }" format="YYYY-MM-DD HH:mm:ss" placeholder="请选择收款时间" />
        </a-col>
      </a-row>
      <a-row class="rows message" v-show="infomationObj && infomationObj.state == 0">
        <span>开票方式:</span>
        <a-col span="16">
          <a-select :disabled="billingAgency == 1" placeholder="请选择开票方式" style="width: 100%" v-model="canInvoice">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option :key="1">线上开票</a-select-option>
            <a-select-option :key="0">线下开票</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <template v-if="canInvoice == 1 && billingAgency == 2">
        <a-row class="rows message" v-show="!invoiceFlag">
          <span>用户发票:</span>
          <a-col span="16">
            <a-select placeholder="请选择用户发票" style="width: 100%" @change="changeInvoice" v-model="invoiceId">
              <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
              <a-select-option v-for="item in invoiceList" :key="item.userInvoiceId">
                {{ item.headerType == 1 ? '企业' : item.headerType == 3 ? '个人' : '其他' }} - {{ item.invoiceHeader }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <a-row class="rows message" v-if="!invoiceFlag">
          <span>未找到？新建一个</span>
        </a-row>
        <div class="linefeed">
          <a-row class="rows message">
            <span>发票类型:</span>
            <a-col span="16">
              <a-select :disabled="invoiceFlag" placeholder="请选择发票类型" style="width: 100%"
                v-model="invoiceForm.headerType">
                <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
                <a-select-option :key="1"> 企业</a-select-option>
                <a-select-option :key="3" v-if="orderMethod != 3">个人/非企业单位</a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row class="rows message">
            <span>发票抬头:</span>
            <a-col span="16">
              <a-input v-show="invoiceForm.headerType != 1" :disabled="invoiceFlag" placeholder="请输入发票抬头"
                v-model="invoiceForm.invoiceHeader" />
              <a-select :disabled="invoiceFlag" v-show="invoiceForm.headerType == 1" show-search
                v-model="invoiceForm.invoiceHeader" placeholder="请输入发票抬头" style="width: 200px"
                :default-active-first-option="false" :show-arrow="false" :filter-option="filterOption"
                :not-found-content="null" @search="headerSearch">
                <!-- @change="headerChange" -->
                <a-select-option @click="headerChange(item.nsrmc)" v-for="item in invoiceHeaderData"
                  :key="item.nsrmc || 'def'">
                  {{ item.nsrmc }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>
        <div class="linefeed">
          <a-row class="rows message">
            <span>发票税号:</span>
            <a-col span="16">
              <a-input :disabled="invoiceFlag" placeholder="请输入税号" v-model="invoiceForm.dutyParagraph" />
            </a-col>
          </a-row>
          <a-row v-if="!invoiceFlag">
            <a-button type="small" class="btn" :loading="btnLoading" @click="addInvoiceData">保存发票</a-button>
          </a-row>
        </div>
      </template>
    </a-modal>
    <!-- 备注弹框 -->
    <a-modal title="备注" :width="400" :closable="false" :visible="remarksVisible" :confirm-loading="confirmLoading"
      @cancel="remarksVisible = false">
      <template slot="footer">
        <a-button type="primary" @click="addremarks">确认</a-button>
        <a-button class="all_boder_btn" style="margin-left: 40px" @click="remarksVisible = false">取消</a-button>
      </template>
      <a-textarea v-model="remarkText" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
    </a-modal>
    <!-- 跟单人弹框 -->
    <a-modal title="跟单人" :width="400" :closable="false" :visible="followerVisible" :confirm-loading="confirmLoading"
      @cancel="followerVisible = false" :maskClosable="false">
      <template slot="footer">
        <a-button type="primary" @click="addFollower">确认</a-button>
        <a-button class="all_boder_btn" style="margin-left: 40px" @click="followerVisible = false">取消</a-button>
      </template>
      <a-select v-model="orderFollowerList" style="width: 100%; margin-top: 10px;" mode="multiple" placeholder="请选择跟单人">
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option :value="item.name" v-for="item in userList" :key="'InstructorList' + item.name">{{ item.name
          }}</a-select-option>
      </a-select>
    </a-modal>
    <!-- 修改收货信息弹框 -->
    <a-modal title="修改收货信息" :width="513" :closable="false" :visible="changeAddressVisible"
      :confirm-loading="confirmLoading" @cancel="changeAddressVisible = false">
      <template slot="footer">
        <a-button type="primary" @click="changeAddress">确认</a-button>
        <a-button class="all_boder_btn" style="margin-left: 40px" @click="changeAddressVisible = false">取消</a-button>
      </template>
      <div class="formItem">
        <p class="formTitle">收货人：</p>
        <a-input v-model="address.receiveName" placeholder="请填写收货人" />
      </div>
      <div class="formItem">
        <p class="formTitle">手机号：</p>
        <a-input v-model="address.receivePhone" placeholder="请填写手机号码" />
      </div>
      <!-- <div class="formItem">
        <p class="formTitle">原地址：</p>
        <p class="value">
          {{ address.provinceName }}{{ address.cityName
          }}{{ address.districtName
          }}{{ address.address }}
        </p>
      </div> -->
      <div class="formItem">
        <p class="formTitle">收货地址：</p>
        <Province @regionCity="FunProvince" :value="address.addressValue" :allowClear="false"></Province>
      </div>
      <div class="formItem">
        <p class="formTitle">详细地址：</p>
        <a-textarea v-model="address.receiveAddress" placeholder="请输入详细地址" />
      </div>
    </a-modal>

    <!-- 发票信息弹框 -->
    <a-modal class="invoceModel" title="发票信息" :width="813" :visible="invoiceVisible" @cancel="invoiceVisible = false"
      :footer="false">
      <a-table class="table-template" :rowKey="(item, index) => index" :columns="columns3" :data-source="invoiceArray"
        @change="onPage" :scroll="{ x: 800, y: 240 }" :pagination="false">
        <template slot="operation" slot-scope="text, record">
          <a :href="record.pdf" target="_block" v-if="record.pdf">查看发票</a>
          <span class="redInvoice" @click="redInvoice(record.invoiceRecordId)" v-if="record.pdf"
            v-hasPermi="['invoice:red:update']">发票作废</span>
          <span v-else>--</span>
        </template>
        <template slot="status" slot-scope="text, record">
          {{
            text == 1
              ? record.isChange == 1
                ? "开票中"
                : "开票中"
              : text == 2
                ? record.isChange == 1
                  ? "已开票"
                  : "已开票"
                : text == 3
                  ? "已作废"
                  : ""
          }}
        </template>
      </a-table>
    </a-modal>

    <!-- 导入物流弹框 -->
    <a-modal title="导入物流" :width="449" :closable="false" :visible="leadingInVisible" :confirm-loading="confirmLoading"
      @cancel="leadingInVisible = false" okText="确认导入">
      <p>上传文件：</p>
      <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76" :before-upload="beforeUpload"
        @change="uploadHandleChange">
        <img v-if="false" :src="imageUrl" alt="avatar" />
        <div v-else>
          <a-icon style="font-size: 28px" :type="loading ? 'loading' : 'cloud-upload'" />
          <div class="ant-upload-text">上传EXCEL</div>
        </div>
      </a-upload>
    </a-modal>
    <Derive v-if="(orderField.length || userField.length) && exportVisible" :visible="exportVisible"
      :handleCancel="modelhandleCancel" @onexportList="onexportList" :selList="selList" :dataList="[
        {
          title: '用户信息',
          options: userField,
        },
        {
          title: '订单信息',
          options: orderField,
        },
      ]" />
    <!-- 导入数据后的展示 -->
    <LeadingIn :columns="columns4" :successList="successList" :failureList="failureList" :visible="importVisible"
      :handleCancel="modelhandleCancel" />
  </div>
</template>

<script>
import Province from "@/components/provinceCity";
import * as XLSX from "xlsx/xlsx.mjs";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log(selected, selectedRows);
  },
};
const columns = [
  {
    title: "序号",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "订单编号",
    align: "center",
    width: "160px",
    dataIndex: "code",
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
    scopedSlots: { customRender: "productName" },
  },
  // {
  //   title: "创建时间",
  //   align: "center",
  //   dataIndex: "createTime",
  // },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "姓名/机构名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "用户类型",
    align: "center",
    dataIndex: "userTypeTxt",
  },
  // {
  //   title: "活动来源",
  //   align: "center",
  //   dataIndex: "source",
  //   scopedSlots: { customRender: "source" },
  // },
  {
    title: "订单来源",
    align: "center",
    dataIndex: "orderSource",
  },
  {
    title: "支付方式",
    align: "center",
    dataIndex: "payMethodTxt",
  },
  {
    title: "支付交易号",
    align: "center",
    dataIndex: "tradeId",
    scopedSlots: { customRender: "tradeId" },
  },
  {
    title: "商品总价",
    align: "center",
    dataIndex: "orderPrice",
  },

  {
    title: "优惠金额",
    align: "center",
    dataIndex: "couponPrice",
  },
  {
    title: "改价",
    align: "center",
    dataIndex: "changePrice",
    scopedSlots: { customRender: "changePrice" },
  },
  {
    title: "实收款",
    align: "center",
    dataIndex: "payPrice",
    scopedSlots: { customRender: "payment" },
  },
  {
    title: "订单状态",
    align: "center",
    dataIndex: "statusTxt",
  },
  {
    title: "退款状态",
    align: "center",
    dataIndex: "refundStatusTxt",
  },
  {
    title: "退款金额",
    align: "center",
    dataIndex: "refundsStatusTxt",
  },
  {
    title: "换课订单类型",
    align: "center",
    dataIndex: "changeProductFlag",
    scopedSlots: { customRender: "changeProductFlag" },
  },
  // {
  //   title: "拆单状态",
  //   align: "center",
  //   dataIndex: "isSplit",
  // },

  // {
  //   title: "商品信息",
  //   align: "center",
  //   width: "200px",
  //   dataIndex: "productName",
  // },
  // {
  //   title: "订单类型",
  //   align: "center",
  //   dataIndex: "orderTypeTxt",
  // },

  // {
  //   title: "运费",
  //   align: "center",
  //   dataIndex: "freightPrice",
  //   scopedSlots: { customRender: "freight" },
  // },
  // {
  //   title: "优惠券",
  //   align: "center",
  //   dataIndex: "couponName",
  // },

  {
    title: "备注",
    align: "center",
    dataIndex: "remark",
  },
  {
    title: "跟单人",
    align: "center",
    dataIndex: "orderFollower",
  },

  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
// 修改学员收货信息表格
const columns2 = [
  {
    title: "用户账号",
    align: "center",
    dataIndex: "accountNumber",
  },
  {
    title: "收货人",
    align: "center",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "收货人手机号",
    align: "center",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "收货地址",
    align: "center",
    dataIndex: "address",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "详细地址",
    align: "center",
    dataIndex: "addressDetail",
    scopedSlots: { customRender: "addressDetail" },
  },
];
const columns3 = [
  {
    title: "订单编号",
    align: "center",
    dataIndex: "subOrderCode",
    width: "200px",
  },
  {
    title: "发票状态",
    align: "center",
    dataIndex: "status",
    width: "150px",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "发票号",
    align: "center",
    dataIndex: "fphm",
    width: "100px",
  },
  {
    title: "发票金额",
    align: "center",
    dataIndex: "price",
    width: "100px",
  },
  {
    title: "商品信息",
    align: "center",
    dataIndex: "productInfo",
    width: "300px",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productTypeTxt",
    width: "100px",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "100px",
    scopedSlots: { customRender: "operation" },
  },
];

// 弹窗内正确错误数据的表头
const columns4 = [
  {
    title: "序号",
    align: "center",
    dataIndex: "serialNumber",
  },
  {
    title: "收货人",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "phone",
  },
  {
    title: "收货地址",
    align: "center",
    dataIndex: "address",
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "orderCode",
  },
  {
    title: "商品编码",
    align: "center",
    dataIndex: "productCode",
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "productNumber",
  },
  {
    title: "物流公司",
    align: "center",
    dataIndex: "logisticsName",
  },
  {
    title: "物流公司编码",
    align: "center",
    dataIndex: "logisticsCode",
  },
  {
    title: "物流单号",
    align: "center",
    dataIndex: "logisticsNumber",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { Province, HeaderBox, Derive, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      /* 下拉模糊商品 */
      productId: undefined,
      shopKeyWord: '',
      productSelectList: [],

      loadExport: false,
      NewloadExport: false,
      selList: null, // 选中列表
      columns4,
      institutionname: '',
      importVisible: false, // 导入弹框
      successList: [], // 正确数据
      failureList: [], // 错误数据
      // 正确错误数据
      orderCode: null, // 操作订单编号
      infomationObj: null, // 对公转账信息
      canInvoice: undefined,
      billingAgency: undefined, // 收款方，1、元道2、出版社
      paymentTime: '', // 收款时间
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      findtime: [], // 筛选时间
      order_type: undefined, // 订单类型
      user_type: undefined, // 用户类型
      payMethod: undefined, // 支付方式
      refund_status: undefined, // 退款状态
      source: undefined, // 活动来源
      is_split: undefined, // 拆单状态
      orderSource: undefined, // 订单来源
      orderFollower: undefined, // 跟单人筛选条件
      start_time: "", // 开始时间
      keyword: "",
      productName: "", // 商品名称
      userName: "", // 用户账号
      code: "", // 订单编码
      changeAddressVisible: false, // 修改收货信息弹框
      remarkText: "", // 备注文本
      remarksVisible: false, // 备注弹框
      publicVisible: false, // 对公转账信息弹框
      exportVisible: false, // 导出弹框
      leadingInVisible: false, // 导入物流弹框
      invoiceVisible: false, // 发票信息弹框
      columns, // 表头
      columns2, // 弹框表头
      columns3, // 发票信息表头
      tableData: [], // 列表数据
      modelTableData: [], //  弹框列表数据
      invoiceArray: [], // 发票信息数据
      loading: false, // 上次图片加载
      loadList: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      rowSelection, // 全选
      isChecked: false,
      selectedRowKeys: [], //选择的id
      visible: false,
      confirmLoading: false,
      address: {
        addressValue: [], // 省市区
        receiveName: "", // 收货人姓名
        receivePhone: "", // 收货人手机号
        receiveAddress: "", // 收货人详细地址
      },
      orderField: [], // 导出字段配置-订单信息
      userField: [], // 导出字段配置-用户信息
      orderId: "", // 当前操作订单id
      tabIndex: 0, // tabIndex
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "Length should be 3 to 5",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "Please select Activity zone",
            trigger: "change",
          },
        ],
        date1: [
          { required: true, message: "Please pick a date", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "Please select at least one activity type",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "Please select activity resource",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "Please input activity form",
            trigger: "blur",
          },
        ],
      },
      options: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          children: [
            {
              value: "hangzhou",
              label: "Hangzhou",
              children: [
                {
                  value: "xihu",
                  label: "West Lake",
                },
              ],
            },
          ],
        },
        {
          value: "jiangsu",
          label: "Jiangsu",
          children: [
            {
              value: "nanjing",
              label: "Nanjing",
              children: [
                {
                  value: "zhonghuamen",
                  label: "Zhong Hua Men",
                },
              ],
            },
          ],
        },
      ],
      invoiceList: [], // 用户已有发票列表
      invoiceForm: {
        headerType: undefined, // 发票类型
        invoiceHeader: '', // 抬头
        dutyParagraph: '', // 税号
      },
      buyUserId: '', // 弹框用户userId
      invoiceId: undefined, // 选中发票
      btnLoading: false,
      invoiceFlag: false,
      invoiceHeaderData: [{}], // 抬头列表
      followerVisible: false, // 跟单人弹框
      orderFollowerList: "", // 跟单人信息数组
      userList: [], // 后台用户列表
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 取消弹框
    modelCancel() {
      this.invoiceHeaderData = [{}];
      this.publicVisible = false;
      this.infomationObj = null;
      this.orderCode = null;
      this.canInvoice = undefined;
    },
    // 抬头搜索
    headerSearch(value) {
      if (value.length < 6) {
        this.invoiceHeaderData = [{}];
        return
      }
      this.$ajax({
        url: '/hxclass-management/invoice/getCompanyInfo',
        params: {
          nsrmc: value
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.invoiceHeaderData = res.data;
        } else {
          this.invoiceHeaderData = [{}];
        }
      })
    },
    headerChange(value) {
      this.invoiceHeaderData.some(item => {
        if (item.nsrmc == value) {
          this.invoiceForm.dutyParagraph = item.nsrsbh;
          return true;
        }
      })
      this.invoiceHeaderData = [{}];
    },
    filterOption(input, option) {
      this.$set(this.invoiceForm, 'invoiceHeader', input)
      // 在此前是可以手动输入，但鼠标离开后，输入的内容不能在输入框内保存，将input的值给到a-select标签的v-model绑定的newSoftName即可实现将手动输入的值赋值给input
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    // 修改收款方
    billingAgencyChange(e) {
      if (e == 1) {
        this.canInvoice = 0;
      }
    },
    // 商品列表模糊搜索
    shophandleSearch(e) {
      this.shopKeyWord = e;
      this.productList = [];
      this.getProductList();
    },
    // 选择弹框商品
    selectProduct(e, option) {
      console.log(e, option)
      this.productSelectList.map(item => {
        if (item.productId == e) {
          this.productName = item.productName
        }
      })
      // let arr = this.productList.filter(item=>{
      //   if(this.form.userType == 2 && this.form.orderType == -1){
      //     return item.id == e;
      //   }
      //   if(this.form.userType == 1 && this.form.orderType == 3){
      //     return item.configId == e;
      //   }
      //   return item.productId == e;
      // })
      // this.modelForm = {
      //   isPackage: this.form.orderType == -1?true:false, // 是否机构包
      //   number: 1, // 数量
      //   openTimeId: undefined, //	开课时间id
      //   productId: e, //	商品id
      //   signupUserId: arr[0].signupUserId,
      //   productType: this.form.userType == 1?arr[0].type:arr[0].productType, //	商品类型
      //   name: this.form.userType == 1 || this.form.orderType == -1?arr[0].name:arr[0].productName, //	商品名称
      //   couponPrice: this.form.userType == 1 && this.form.orderType != 1?this.form.orderType == 2?20:0:arr[0].couponPrice // 单价
      // }
      // this.openTimeList = [];
      // if(this.form.userType == 1 && this.modelForm.productType == 1) {
      //   this.getOpenTimeList(arr[0].courseId);
      // }
    },
    // 获取商品列表
    getProductList() {
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/list/productname",
        params: {
          productName: this.shopKeyWord
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.productSelectList = res.data;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 查看大图
    lookImg(e) {
      window.open(e)
    },
    // 跳转
    godetail(e, r) {
      if (r == 2) {
        this.$router.push("/admin/OrderConfig/orgOrderDetail?orderId=" + e);
      } else {
        this.$router.push("/admin/OrderConfig/OrderDetail?orderId=" + e);
      }
    },
    // 发票信息
    invoiceMessage(e) {
      this.$ajax({
        url: "/hxclass-management/order/list/invoice",
        method: "get",
        params: {
          orderId: e.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceArray = res.data;
          this.invoiceVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导入基础信息
    fileAction(e) {
      // // console.log(e);
      // this.handle(e);
      // return;
      this.$ajax({
        url: "/hxclass-management/order/excel/batch/shipment",
        method: "POST",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.successData;
          this.failureList = res.data.errorData;
          // this.$message.success(res.message);
          this.importVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //文件读取
    readFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file); //以二进制的方式读取
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    // 解析文件，异步走方法，防止问题
    async handle(ev) {
      let file = ev.file;
      // console.log(file);
      if (!file) {
        // console.log("文件打开失败");
        return;
      } else {
        let data = await this.readFile(file);
        let workbook = XLSX.read(data, { type: "binary" }); //解析二进制格式数据
        // console.log("二进制数据的解析:");
        // console.log(workbook);
        let worksheet = workbook.Sheets[workbook.SheetNames[0]]; //获取第一个Sheet
        let result = XLSX.utils.sheet_to_json(worksheet); //json数据格式
        // console.log("最终解析的 json 格式数据:");
        // console.log(result);
        for (let index = 0; index < result.length; index++) {
          // console.log(result[index].物流公司);
          if (
            result[index].物流公司 &&
            result[index].物流公司编码 &&
            result[index].物流单号
          ) {
            this.successList.push(result[index]);
          } else {
            this.failureList.push(result[index]);
          }
        }
        // 循环完成展示
        this.importVisible = true;
      }
    },
    // 下载错误数据
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      this.$ajax({
        url: "/hxclass-management/order/error/batch/export",
        method: "POST",
        responseType: "blob",
        params: this.failureList,
      }).then((res) => {
        let objectUrl = window.URL.createObjectURL(new Blob([res]));
        const elink = document.createElement("a");
        elink.download = `错误文件.xlsx`; //下载文件名称,
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(elink.href);
      });
      // this.blobDownload(this.failureList, "错误文件");
    },

    // blob文件转excel
    blobDownload(data, name) {
      let m = this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name + ".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList() {
      // let arr = [];
      // arr = JSON.stringify(this.successList);
      this.$ajax({
        url: "/hxclass-management/order/success/batch/shipment",
        method: "POST",
        params: this.successList,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getData();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //
    // 导出
    onexportList(e) {
      // this.selList = JSON.parse(JSON.stringify(e));
      // let arr = this.orderField.filter((item) => {
      //   return item.checked;
      // });
      // let array = this.userField.filter((item) => {
      //   return item.checked;
      // });
      // arr = arr.map((item) => {
      //   return {
      //     name: item.name,
      //     code: item.value,
      //   };
      // });
      let array = e.map((item) => {
        return {
          name: item.name,
          code: item.value,
        };
      });
      window.open(
        this.$config.target +
        "/hxclass-management/order/excel/export?field_map=" +
        encodeURIComponent(JSON.stringify([...array])) +
        "&order_type=" +
        encodeURIComponent(this.order_type ? this.order_type : "") +
        "&productName=" +
        encodeURIComponent(this.productName) +
        "&userName=" +
        encodeURIComponent(this.userName) +
        "&code=" +
        encodeURIComponent(this.code) +
        "&refund_status=" +
        encodeURIComponent(this.refund_status ? this.refund_status : "") +
        "&source=" +
        encodeURIComponent(this.source ? this.source : "") +
        "&start_time=" +
        encodeURIComponent(this.findtime[0] ? this.findtime[0] : "") +
        "&end_time=" +
        encodeURIComponent(this.findtime[1] ? this.findtime[1] : "") +
        "&user_type=" +
        encodeURIComponent(this.user_type ? this.user_type : "") +
        "&type=" +
        encodeURIComponent(this.tabIndex ? this.tabIndex : "") +
        "&payMethod=" +
        encodeURIComponent(this.payMethod ? this.payMethod : "")
      );
    },
    // 导出获取导出字段
    getExportlist() {
      if (this.orderField.length || this.userField.length) {
        return (this.exportVisible = true);
      }
      this.$ajax({
        url: "/hxclass-management/order/excel/export/field",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.orderField = res.data.orderField.map((item) => {
            return {
              name: item.name,
              value: item.code,
              checked: true,
              disabled: false,
            };
          });
          this.userField = res.data.userField.map((item) => {
            return {
              name: item.name,
              value: item.code,
              checked: true,
              disabled: false,
            };
          });
          this.exportVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 财务订单导出功能
    exportMoneyOrder() {
      if (!this.findtime.length) {
        return this.$message.warning("请先选择支付时间");
      }
      // let form = {
      //   start: this.findtime[0],
      //   end: this.findtime[1]
      // }
      this.loadExport = true;
      this.$ajax({
        url: "/hxclass-management/order/finance/export?start=" + this.findtime[0] + '&end=' + this.findtime[1],
        method: "POST",
        responseType: "blob",
        // params: form,
      }).then((res) => {
        this.loadExport = false;
        let objectUrl = window.URL.createObjectURL(new Blob([res]));
        const elink = document.createElement("a");
        elink.download = `财务订单.xlsx`; //下载文件名称,
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(elink.href);
      });
    },
    // 订单导出升级版
    exportnewOrder() {
      if (!this.findtime.length) {
        return this.$message.warning("请先选择支付时间");
      }
      this.NewloadExport = true;
      this.$ajax({
        url: "/hxclass-management/order/export/new?start=" + this.findtime[0] +
          '&end=' + this.findtime[1] + '&orderType=' + (this.order_type ? this.order_type : '') +
          '&payMethod=' + (this.payMethod ? this.payMethod : '') + '&userType=' + (this.user_type ? this.user_type : ''),
        method: "post",
        responseType: "blob",
      }).then((res) => {
        this.NewloadExport = false;
        let objectUrl = window.URL.createObjectURL(new Blob([res]));
        const elink = document.createElement("a");
        elink.download = `订单.xlsx`; //下载文件名称,
        elink.style.display = "none";
        elink.href = objectUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(elink.href);
      });
    },// 新建抬头
    addInvoiceData() {
      if (!this.buyUserId) {
        this.$message.warning("请选择用户");
        return;
      }
      if (!this.invoiceForm.headerType) {
        this.$message.warning("请选择发票类型");
        return;
      }
      if (!this.invoiceForm.invoiceHeader) {
        this.$message.warning("请填写发票抬头");
        return;
      }
      if (this.invoiceForm.headerType == 1 && !this.invoiceForm.dutyParagraph) {
        this.$message.warning("请填写税号");
        return;
      }
      this.btnLoading = true;
      let obj = JSON.parse(JSON.stringify(this.invoiceForm));
      obj['userId'] = this.buyUserId;
      this.$ajax({
        url: '/hxclass-management/invoice/header/insert',
        method: "post",
        params: obj
      }).then(res => {
        this.btnLoading = false;
        if (res.code == 200 && res.success) {
          this.invoiceId = res.data;
          this.getUserInvoiceList(this.buyUserId);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 查询用户已有发票列表
    getUserInvoiceList(userId) {
      this.$ajax({
        url: '/hxclass-management/invoice/header/useList',
        params: {
          userId
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          // 对公转账订单筛选掉个人类型发票
          if (this.orderMethod == 3) {
            let arr = res.data.filter(item => {
              return item.headerType == 1
            })
            return this.invoiceList = arr;
          }
          // 非对公转账订单发票列表正常显示
          this.invoiceList = res.data;
        } else {
          this.invoiceList = [];
          this.$message.error(res.message);
        }
      })
    },
    // 选择发票
    changeInvoice(e) {
      this.invoiceList.map(item => {
        if (item.userInvoiceId == e) {
          this.$set(this.invoiceForm, 'headerType', item.headerType)
          this.$set(this.invoiceForm, 'invoiceHeader', item.invoiceHeader)
          this.$set(this.invoiceForm, 'dutyParagraph', item.dutyParagraph)
        }
      })
    },
    // 对公转账审核弹框
    async infomationExamine(record) {
      // 重置信息
      this.orderMethod = record.payMethod; // 存储订单支付类型控制对公转账禁选个人类型发票
      this.buyUserId = record.userId;
      this.invoiceId = undefined;
      this.invoiceForm = {
        headerType: undefined,
        invoiceHeader: '',
        dutyParagraph: ''
      }
      await this.getUserInvoiceList(record.userId);
      this.orderCode = record.code;
      this.$ajax({
        url: "/hxclass-management/order/transfer/details",
        method: "get",
        params: {
          orderId: record.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.userInvoiceId) {
            this.invoiceFlag = (res.data.state ? true : false);
            this.canInvoice = (res.data.state ? res.data.isInvoice : undefined);
            let isHaveInvoice = this.invoiceList.some(function (item) {
              return item.userInvoiceId === res.data.userInvoiceId;
            })
            this.invoiceId = (isHaveInvoice ? res.data.userInvoiceId : undefined);
            setTimeout(() => {
              this.changeInvoice(res.data.userInvoiceId);
            }, 200)
          } else {
            this.invoiceFlag = false;
          }
          this.billingAgency = res.data.billingAgency;
          this.paymentTime = res.data.paymentTime;
          if (res.data.img) {
            res.data.img = res.data.img.split(',');
          } else {
            res.data.img = [];
          }
          this.infomationObj = res.data;
          this.publicVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    remarksAll() {
      if (!this.selectedRowKeys.length) {
        return this.$message.warning("请先选择订单");
      }
      this.remarksVisible = true;
    },
    // 导出报表
    exportTable() {
      if (!this.findtime.length) {
        return this.$message.warning("请先选择支付时间");
      }
      window.open(
        this.$config.target +
        "/hxclass-management/order/returned/money?startTime=" +
        (this.findtime[0] ? this.findtime[0] : "") +
        "&endTime=" +
        (this.findtime[1] ? this.findtime[1] : "")
      );
    },
    // 获取省市区编码
    FunProvince(data) {
      this.$set(this.address, "addressValue", data);
    },
    // 下拉订单类型
    orderTypeSelect(e) {
      // console.log(e, this.order_type);
    },
    uploadHandleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    // 上传文件
    beforeUpload(file) {
      const isJpgOrPng = file.type == "application/vnd.ms-excel";
      if (!isJpgOrPng) {
        this.$message.error("您上传的文件格式不正确，请下载模板!");
      }
      return isJpgOrPng;
    },
    // 选择新地址
    addressCascader(e) {
      // console.log(e);
    },
    // 修改收货信息保存按钮
    changeAddress() {
      if (!this.address.receiveName) {
        return this.$message.warning("请输入收货人姓名");
      } else if (!this.address.receivePhone) {
        return this.$message.warning("请输入手机号");
      } else if (!this.$regular.phone.reg.test(this.address.receivePhone)) {
        return this.$message.warning("请输入正确的手机号");
      } else if (
        !this.address.addressValue ||
        !this.address.addressValue.length
      ) {
        return this.$message.warning("请选择收货地址");
      } else if (!this.address.receiveAddress) {
        return this.$message.warning("请输入详细地址");
      } else {
        let _params = {
          orderId: this.orderId,
          receiveName: this.address.receiveName,
          receivePhone: this.address.receivePhone,
          receiveAddress: this.address.receiveAddress,
          receiveProvince: this.address.addressValue[0],
          receiveCity: this.address.addressValue[1],
          receiveDistrict: this.address.addressValue[2],
        };
        this.$ajax({
          url: "/hxclass-management/order/personal/address",
          method: "put",
          params: _params,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.info("修改成功");
            this.changeAddressVisible = false;
            this.orderId = "";
            this.getManageList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 添加备注确认按钮
    addremarks() {
      if (!this.remarkText) {
        this.$message.warning("请输入备注信息");
      } else {
        this.$ajax({
          url: "/hxclass-management/order/remarks",
          method: "put",
          params: {
            orderIds: this.selectedRowKeys,
            remark: this.remarkText,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.info("添加成功");
            this.getManageList();
            this.remarksVisible = false;
            this.remarkText = "";
            this.selectedRowKeys = [];
            this.isChecked = false;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 发票作废
    redInvoice(e) {
      let _this = this;
      this.$confirm({
        title: "是否确认作废该发票？",
        okText: "确认",
        onOk() {
          _this.$message.loading({ content: "正在处理...", duration: 0 });
          _this
            .$ajax({
              url: "/hxclass-management/invoice/red?invoiceRecordId=" + e,
              method: "post"
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success('操作成功');
                _this.invoiceArray.map((item, index) => {
                  if (item.invoiceRecordId == e) {
                    _this.invoiceArray.splice(index, 1);
                  }
                })
              } else {
                _this.$confirm({
                  title: res.message,
                  okText: "知道了",
                  type: 'warning',
                  cancelButtonProps: { style: { display: 'none' } },
                  okButtonProps: { style: { margin: '0 auto' } },
                  onOk() { }
                });
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 对公转账信息弹框确认按钮
    paymented() {
      if (!this.billingAgency) {
        return this.$message.warning('请选择收款方!')
      }
      if (!this.paymentTime) {
        return this.$message.warning('请选择收款时间!')
      }
      if (this.canInvoice === undefined) {
        return this.$message.warning('请选择开票方式!')
      } else if (this.canInvoice == 1 && !this.invoiceId) {
        return this.$message.warning('请选择或保存发票后提交!')
      }
      let _this = this;
      let params = {
        canInvoice: _this.canInvoice,
        id: _this.infomationObj.id,
        payPrice: _this.infomationObj.price,
        orderCode: _this.orderCode,
        isInvoice: _this.canInvoice,
        invoiceId: _this.invoiceId,
        billingAgency: _this.billingAgency,
        paymentTime: _this.moment(_this.paymentTime).format("YYYY-MM-DD HH:mm:ss")
      }
      // 若收款方为元道 传值修改
      if (_this.billingAgency == 1) {
        params.isInvoice = 0;
        delete params.invoiceId;
      }
      this.$confirm({
        title: "是否确认用户已完成转账？",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/order/transfer/audit",
              method: "put",
              params
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.getManageList();
                _this.orderCode = null;
                _this.canInvoice = undefined;
                _this.infomationObj = null;
                _this.publicVisible = false;
                _this.billingAgency = undefined;
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    modelhandleCancel(e) {
      if (e) {
        this.selList = JSON.parse(JSON.stringify(e));
      } else {
        this.selList = null;
      }
      this.exportVisible = false;
      this.importVisible = false;
    },
    dataConsole() {
      // console.log(this.modelTableData);
    },
    // 弹框功能
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      // console.log("Clicked cancel button");
      this.visible = false;
    },
    handleChange() { },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.orderId;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    // tab切换
    callback(e) {
      this.tabIndex = e;
      this.pageNumber = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    // 跳详情页
    goDetails(e) {
      this.$router.push({
        path: "/OrderConfig/OrderDetail",
        query: { orderId: e.orderId },
      });
    },
    // 重置
    reset() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.order_type = undefined;
      this.productName = ""; // 商品名称
      this.userName = ""; // 用户账号
      this.code = ""; // 订单编码
      this.refund_status = undefined;
      this.source = undefined;
      this.start_time = "";
      this.findtime = [];
      this.user_type = undefined;
      this.payMethod = undefined;
      this.is_split = undefined;
      this.orderSource = undefined;
      this.orderFollower = undefined;
      this.productId = undefined;
      this.getManageList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getManageList();
    },
    // 获取列表数据
    getManageList() {
      this.loadList = true;
      this.$ajax({
        url: "/hxclass-management/order/list",
        method: "get",
        params: {
          page_num: this.pageNumber,
          page_size: this.pageSize,
          order_type: this.order_type,
          is_split: this.is_split,
          orderSource: this.orderSource,
          orderFollower: this.orderFollower,
          productName: this.productName,
          userName: this.userName,
          code: this.code,
          refund_status: this.refund_status,
          source: this.source,
          start_time: this.findtime[0] ? this.findtime[0] : "",
          end_time: this.findtime[1] ? this.findtime[1] : "",
          user_type: this.user_type,
          payMethod: this.payMethod,
          type: this.tabIndex ? this.tabIndex : "",
          productId: this.productId ? this.productId : '',
          name: this.institutionname,
        },
      }).then((res) => {
        this.loadList = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 获取订单详情
    getOrderDetail(e) {
      this.orderId = e;
      this.changeAddressVisible = true;
      this.$ajax({
        url: "/hxclass-management/order/detail/personal/" + e,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.address = res.data;
          this.address.receiveName = res.data.shipAddress.name;
          this.address.receivePhone = res.data.shipAddress.phone;
          this.address.provinceName = res.data.shipAddress.provinceName;
          this.address.cityName = res.data.shipAddress.cityName;
          this.address.districtName = res.data.shipAddress.districtName;
          this.address.address = res.data.shipAddress.address;
          this.address.addressValue = [
            res.data.shipAddress.province,
            res.data.shipAddress.city,
            res.data.shipAddress.district,
          ];
          this.address.receiveAddress = res.data.shipAddress.address;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取用户信息
    getUserList() {
      this.$ajax({
        url: "/hxclass-management/user/info/manage/user/list",
        method: "get",
        params: { "pageNum": 1, "pageSize": 100 },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = res.data.records.filter((item) => {
            return item.name === "赵雪" || item.name === "卫清静" || item.name === "汪玲" || item.name === "杨柳" || item.name === "杨少云";
          });
          this.userList = arr;
          this.userList.push("无");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 添加跟单人
    addFollower() {
      // if (this.orderFollowerList.length == 0) {
      //   this.$message.warning("请选择跟单人");
      //   return
      // }
      this.$ajax({
        url: "/hxclass-management/order/remarks",
        method: "put",
        params: {
          orderIds: [this.orderId],
          orderFollower: this.orderFollowerList.length == 0 ? "无" : this.orderFollowerList.join("、"),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.info("添加成功");
          this.getManageList();
          this.followerVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 处理跟单人数据
    handleFollowerList(follower) {
      if (follower) {
        this.orderFollowerList = follower.split("、")
      } else {
        this.orderFollowerList = [];
      }

    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.payMethod) {
      this.payMethod = this.$route.query.payMethod
      this.tabIndex = 1
    }
    this.getProductList();
    this.getManageList();
    this.getUserList();
  },
  // 生命周期-实例挂载后调用
  mounted() { },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.invoceModel {
  /deep/.ant-modal-close {
    display: block;
  }
}

/deep/.cascader {
  flex: 1;

  .ant-cascader-picker {
    width: 100% !important;
  }
}

.formItem {
  display: flex;
  margin-bottom: 24px;

  .formTitle {
    width: 80px;
    font-size: 12px;
    color: #000000;
    line-height: 32px;
    text-align: center;
  }

  .ant-input,
  .ant-cascader-picker {
    flex: 1;
  }

  .value {
    flex: 1;
  }
}

.message {
  margin-bottom: 24px;
  width: 50%;
}

.img {
  display: flex;

  .imgs {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  img {
    width: 200px;
    margin-top: 10px;
    cursor: pointer;
  }

  img:first-child {
    margin-top: 0;
  }

  img:nth-child(2) {
    margin-top: 0;
  }
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .ant-upload-text {
    font-size: 12px;
    color: #666666;
    line-height: 14px;
  }
}

/deep/.ant-upload {
  width: 324px;
  margin: 0;
}

.select {
  text-align: right;

  /deep/.ant-select {
    width: 193px !important;
    height: 29px !important;
    margin-right: 0 !important;
    margin-left: 24px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
  }
}

.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  // /deep/.ant-form-item-control-wrapper{
  //   width: 240px !important;
  //   height: 29px !important;
  // }
  /deep/.ant-input {
    width: 300px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }

  .ant-btn {
    margin-right: 24px;
  }

  .ant-btn:last-child {
    margin-right: 0;
  }
}

.btnBorder {
  border-color: #3681ef;
  color: #3681ef;
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.productName {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selects {
  width: 1150px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  justify-content: space-between;
}

.rows {
  display: flex;
  align-items: center;

  span {
    width: 60px;
    text-align: right;
    display: inline-block;
    margin-right: 10px;
  }
}

.redInvoice {
  color: red;
  cursor: pointer;
}

.linefeed {
  display: flex;
}
</style>
